html {
  background-color: white;
}

body {
  margin: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pin-button {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  outline: none;
  touch-action: manipulation;
}

.pin {
  width: 100%;
  height: 100%;
}

#item-zoomer {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: relative;
  width: fit-content;
  height: fit-content;
}
